import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Mocktails from './components/Mocktails';
import Partnerships from './components/Partnerships';
import Membership from './components/Membership';
import CoreValues from './components/CoreValues'; // Import Core Values
import Footer from './components/Footer'; // We'll add the footer next
import './App.css'; // Ensure it's the correct path


function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Hero />
      <About />
      {/* <Mocktails />
      <Partnerships />
      <CoreValues /> 
      <Membership /> */}
      {/* <Footer />  */}
    </div>
  );
}

export default App;
