import React from 'react';
import './../styles/Header.css'; // Correct CSS import

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src="/Logo.png" alt="Nouni House" />
      </div>
      <nav>
        <ul>
          <li><a href="#">Coming Soon...</a></li>
          {/* <li><a href="#about">About</a></li>
          <li><a href="#mocktails">Mocktails</a></li>
          <li><a href="#partnerships">Partnerships</a></li>
          <li><a href="#core-values">Core Values</a></li>
          <li><a href="#membership">Membership</a></li> */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
