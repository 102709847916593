import React from 'react';
import { motion } from 'framer-motion';
//import './../styles/Hero.css';
import './../styles/Hero1.css';

const Hero1 = () => {
  return (
    <section className="hero">
      <div className="logo-container">
        <img src="/Logo.png" alt="Nouni House Logo" className="logo" />
      </div>
      <div className="hero-content">
        <h1>Nouni House</h1>
        <nav className="hero-nav">
          <a href="#about">MISSION</a> | 
          <a href="https://www.instagram.com/nounihouse" target="_blank" rel="noopener noreferrer">INSTAGRAM</a> | 
          <a href="https://www.instagram.com/nounihouse" target="_blank" rel="noopener noreferrer">CONTACT</a>
        </nav>
      </div>
    </section>
  );
};

const Hero = () => {
  return (
    <div>
    <motion.section
      className="hero"
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 1.5 }}
    >
      <div className="hero-content">
        test
        {/* Hero content could go here (image or text) */}
      </div>
    </motion.section>
    <div className="coming-soon-button">
      <a href="https://www.instagram.com/nounihouse" target="_blank" rel="noopener noreferrer">
        <button>Coming Soon...</button>
      </a>
    </div>
  </div>
  );
};

export default Hero1;
