import React from 'react';
import { motion } from 'framer-motion';
import './../styles/About.css'; // Correct CSS import path

const About = () => {
  return (
    <motion.section
      className="about"
      id="about"
      initial={{ opacity: 0, y: 50 }} // Hidden and shifted down
      whileInView={{ opacity: 1, y: 0 }} // Fade and move into position
      transition={{ duration: 0.8 }} // Animation speed
      viewport={{ once: true }} // Only animate once
    >
      <h2>Our Mission</h2>
      <p>
        At Nouni House, we craft wellness-driven mocktails inspired by a rich tapestry of global cultures 
        and traditions. Rooted in the warmth and vibrancy of my Middle Eastern heritage and enriched by 
        diverse travels, our beverages celebrate balance, mindfulness, and the beauty of imperfection.
      </p>
      <p>
        Embracing the wabi-sabi philosophy, we find elegance in simplicity and authenticity, creating drinks 
        that honor the natural world and its subtle, unique beauty. Each thoughtfully curated, plant-based 
        beverage is designed to foster connection, celebrate the art of mindful living, and share the healing 
        power of nature with every sip.
      </p>
    </motion.section>
  );
};

export default About;
